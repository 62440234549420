import YoutubePlayer from 'youtube-player';

$.fn.lightBox = function(options){
    var defaults = {
      obj: $(this),
      type: '',
      objLightBox: '',
      animate: {},
      animateToggle: {},
      backgroundClickToClose: true,
      objClose: $('[data-lightbox-close]'),
    }
    var settings = $.extend(defaults, options);

    // console.log(settings);

    let outerPopup = `
    <div id="renderedPopup" class="outer outer--popup followPopup">
    </div>`;

    if ($("#renderedPopup").length == 0){
        $(".outer--popup .inner").append(outerPopup);
    }

    let popupId;
    let popupDom;
    let ytPlayer = {};
  
    switch (settings.type) {
        case 'youtube': 
            popupId = generateId();
            var youtubeId = settings.obj.attr("href").split("=")[1];
            settings.obj.attr("href", "#"+popupId);

            // console.log(settings.obj.attr("href"));

            popupDom = `
                <div id="${popupId}" class="popup popup--open-video">
                    <div class="popup__background"></div>
                    <div class="popup__content">
                        <div class="embed"><div id="${popupId}_embed" data-youtube-id="${youtubeId}"></div></div>
                    </div>
                </div>
            `;
            $("#renderedPopup").append(popupDom);

            $("#"+popupId+" .popup__background").on("click", function(){
                console.log('a');
                $(this).parent().removeClass("active");
                if ($(this).parent().hasClass("popup--open-video") && (ytPlayer["#"+$(this).parent().attr("id")] != undefined)) {
                    ytPlayer["#"+$(this).parent().attr("id")].pauseVideo();
                }
            })
            break;
        case 'image':
            popupId = generateId();
            let imgUrl = settings.obj.attr("href");
            settings.obj.attr("href", "#"+popupId);

            popupDom = `
                <div id="${popupId}" class="popup popup--open-image">
                    <div class="popup__background"></div>
                    <div class="popup__content">
                        <div class="image"><img src="${imgUrl}" /></div>
                    </div>
                </div>
            `;
            $("#renderedPopup").append(popupDom);
            $("#"+popupId+" .popup__background").on("click", function(){
                console.log('a');
                $(this).parent().removeClass("active");
            
            })
            break;
           
            break;
        default: 
        
    }

    settings.obj.on("click", (e) => {
        e.preventDefault();
        $(".popup").removeClass("active");
        
        if ($(this).data("lightbox-type") == "youtube" && ytPlayer[$(this).attr("href")] == undefined ) {
            ytPlayer[$(this).attr("href")] = YoutubePlayer($(this).attr("href").substring(1)+"_embed", {
                videoId: $($(this).attr("href")+"_embed").data("youtube-id"),
                playerVars: {
                    rel: 0,
                    autoplay: 1,
                    color: 'white'
                }
            });
            // ytPlayer[$(this).attr("href")].stopVideo();
        }
        if ($(this).data("lightbox-type") != "none") {
            $($(this).attr("href")).addClass('active');
            $($(this).attr("href")).animate(settings.animate);
        }
        if ($(this).data("lightbox-type") == "youtube") {
            ytPlayer[$(this).attr("href")].playVideo();
        }
      
    });

    settings.objClose.on("click", function(){
        $(".popup").removeClass("active");
        if ($(this).parent().hasClass("popup--open-video")) {
            ytPlayer["#"+$(this).parent().attr("id")].pauseVideo();
        }
    })
    

    // Inline
}


function generateId(){
    return Math.random().toString(36).substring(2, 8);
}


if ($(".popup__background").length > 0) {
 
    $(".popup__background").each(function(){
        $(this).on("click", function(){   
            $(this).parent().removeClass("active");
            if ($(this).parent().hasClass("popup--open-video") && (ytPlayer["#"+$(this).parent().attr("id")] != undefined)) {
                ytPlayer["#"+$(this).parent().attr("id")].pauseVideo();
            }
        })
    })
}