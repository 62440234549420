


var parentpop;
$(".close-popup").on("click", function (e) {
    e.preventDefault();
    console.log(this);
    $(".popup").removeClass("active");
});





var isOpenNoti = false;
$(".button-noti").on("click", function (e) {
    e.preventDefault();
    if (!isOpenNoti) {
        $(".container-notification").addClass("active");
        isOpenNoti = true;
    }
    else {
        isOpenNoti = false;
        $(".container-notification").removeClass("active");
    }
});

var isOpenUserFunction = false;
$(".button-open-user-function").on("click", function (e) {
    e.preventDefault();
    if (!isOpenUserFunction) {
        $(".user-function").addClass("active");
        $(".user-infor .icon").addClass("active");
        isOpenUserFunction = true;
    }
    else {
        isOpenUserFunction = false;
        $(".user-function").removeClass("active");
        $(".user-infor .icon").removeClass("active");
    }
});

$(".btn-search").on("click", function (e) {
    e.preventDefault();
    $('.form-search').addClass('active');
});
$(".btn-close-search").on("click", function (e) {
    e.preventDefault();
    $('.form-search').removeClass('active');
});

//open edit 


// close edit
// $(".button-submit").on("click", function (e) {
//     e.preventDefault();
//     formedit = $(this).attr("data-href");
//     $("." + formedit + " .container-bot").addClass("mode-view");
//     $("." + formedit + " .container-bot").removeClass("mode-edit");
//     $("." + formedit + " .add-img").removeClass("hiden");
//     $("." + formedit + ' .delete').removeClass("active");
//     $("." + formedit + ' .button-edit').removeClass("hiden");
//     $("." + formedit + ' .button-submit').removeClass("active");
// });