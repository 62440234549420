$.fn.activeNav = function(options){
    var defaults = {
      obj: $(this),
      dataNavActive: "nav-active",
      dataNav: "nav",
      objToActive: ".activeNav"
    }
    var settings = $.extend(defaults, options);
    let thisPage = settings.obj.data(settings.dataNavActive);
    $(settings.objToActive).children("ul").children("li").children("a").removeClass("active");
    $(settings.objToActive).children("ul").children("li").children('[data-'+settings.dataNav+'="'+thisPage+'"]').addClass("active");

}
if ($(".page").length > 0) {
    $(".page").eq(0).activeNav(); 
}

$(".burger").on("click", function (e) {
  e.preventDefault();
  if( $(this).hasClass("activeclose")){
    $(this).removeClass("activeclose");
    $("nav ul").removeClass("active");
  }
  else{
    $(this).addClass("activeclose");
    $("nav ul").addClass("active");
  }
 
});


