 
function viewportIsMobile(width){
    return (width < 841);
}




import "./components/_lightbox";



if ($("[data-lightbox]").length > 0) {
    $("[data-lightbox]").each(function () {
        $(this).lightBox({
            objLightBox: $(this).attr('href'),
            type: $(this).data('lightbox-type')
        });
    });
}
var userAgent = navigator.userAgent || navigator.vendor || window.opera || navigator.platform;
if (/(Mac|iPhone|iPod|iPad)/i.test(userAgent) && !window.MSStream) {
    $(".navigation__container span").addClass('ios-padding');
    $(".popup .form-register .submit").addClass('ios-padding');
}

import "./components/_control-layout";
import "./components/_activeNav";
import "./components/_scaleImageBase64";
