import "lazysizes";
import Scrollbar from 'smooth-scrollbar';

import BezierEasing from "bezier-easing";

// SCROLL
// -- Init

if ($(".scrollContent").length > 0) {
  let scrollbars = {};
  scrollbars['root'] = Scrollbar.init($(".scrollContent")[0], {
    alwaysShowTracks: true
  });
}
if ($(".scrollArticle").length > 0) {
  scrollbars['article'] = Scrollbar.init($(".scrollArticle")[0], {
    alwaysShowTracks: true
  })
}
if ($(".scrollPromo").length > 0) {
  scrollbars['promo'] = Scrollbar.init($(".scrollPromo")[0], {
    alwaysShowTracks: true
  })
}

import Swiper from 'Swiper';
import 'swiper/css/swiper.min.css';
var swiper = new Swiper('.news-swiper', {
  effect: 'cube',
  grabCursor: true,
  speed: 700,
  allowTouchMove: false,
  cubeEffect: {
    shadow: true,
    slideShadows: true,
    shadowOffset: 70,
    shadowScale: 0.7,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  // autoplay: {
  //   delay: 8000,
  //   disableOnInteraction: false,
  // }
});

var swiper_tour = new Swiper('.tour-swiper', {
  allowTouchMove: false,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  // autoplay: {
  //   delay: 8000,
  //   disableOnInteraction: false,
  // }
});


var width =  $("body").outerWidth();
var height =  $("body").outerHeight();
$(window).on("resize", function(){
  width =  $("body").outerWidth();
  height =  $("body").outerHeight();
});
// if(viewportIsMobile(width)){
//   if($('.swiper-menu').length>0){
//     $('.swiper-menu').addClass("swiper-container");
//     $('.swiper-menu .menu-article').addClass("swiper-wrapper");
//     $('.swiper-menu .menu-article li').addClass("swiper-slide");
   
//     var swiper_menu = new Swiper('.swiper-menu', {
//       centeredSlides: false,
//       slidesPerGroup:1,
//       allowTouchMove: true,
//       slidesPerView: 3,
//       spaceBetween: 30,
    
//     });
//   }
// }

function viewportIsMobile(width) {
  return (width < 841);
}

