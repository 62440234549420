
var formedit;
$(document).on('click', '.button-edit', function (e) {
    e.preventDefault();
    formedit = $(this).attr("data-href");
    $("." + formedit + " .container-bot").removeClass("mode-view");
    $("." + formedit + " .container-bot").addClass("mode-edit");
    $("." + formedit + " .add-img").addClass("hiden");
    $("." + formedit + ' .delete').addClass("active");
    $("." + formedit + ' .button-edit').addClass("hiden");
    $("." + formedit + ' .button-submit').addClass("active");
    $('.popup-loading').addClass('active');
    reCaptchaToken = grecaptcha.getResponse(recaptchaId);
    // if no token, mean user is not validated yet
    if (!reCaptchaToken) {
        // trigger validation
        grecaptcha.reset(recaptchaId);
        grecaptcha.execute(recaptchaId);
    }
});
// input 2 cái đầu là bên edit team , mấy cái sau bên register
var parent;
$(document).on('click', '.delete', function (e) {
    e.preventDefault();
    parent = $(this)[0].parentNode.id;
    $('#' + parent + ' .frame-img .img-user').attr("src", "");
    $('#'+ parent + ' .base64-hidden-input').val("");
    $('#' + parent + ' .add-img').removeClass("hiden");
    $('#' + parent + ' .delete').removeClass("active");
});
$(document).on('click', '#team-avatar .add-img,#personal-avatar .add-img,#logo-team .add-img,#ava-lead .add-img,#ava-member1 .add-img,#ava-member2 .add-img,#ava-member3 .add-img', function (e) {
    e.preventDefault();
    parent = $(this)[0].parentNode.id;
    $('#'+parent+' input').click();
});
$(document).on('change', '#input-avatar-team,#input-avatar-personal,#input-logo-team,#input-avatar-lead,#input-avatar-member1,#input-avatar-member2,#input-avatar-member3', function () {
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        });
        var nameid= $(this)[0].id;
        var parentid = $(this)[0].parentNode.id;
       async function Main() {
            const file = $("#"+nameid)[0].files[0];
             url= await toBase64(file);
           $('#'+parentid+' .frame-img .img-user').attr("src", url);
           $('#'+parentid+' .base64-hidden-input').val(url);
           $('#'+parentid+' .add-img').addClass("hiden");
           $('#'+parentid+' .delete').addClass("active");
        //    scaleImage(url);
        }
        Main();
});



//scale

// import '../../../../node_modules/jcanvas/dist/min/jcanvas.min.js';

// var Base64Image ="";
// function scaleImage(url){
//     var $c = $( '<canvas>' );
//     console.log(url);
//     $c.drawImage({
//         source: url,
//         x: 50, y: 50,
//         width: 200,
//         height: 200,
//         fromCenter: false
//       });
//     Base64Image =  $c.getCanvasImage();
//     console.log(Base64Image);
// }


