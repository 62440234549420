
var icons = ['edit','kick','close','next','down','noti','sort','search','reg1','reg2','reg3','upload','delete','uploadsmall','leader'];
var svg;
icons.forEach(function(v) {

	// $('.icon[data-icon="'+v+'"]').html(require('../svg/'+v+'.svg'));
	svg =require('../svg/'+v+'.svg') ;
	$('.icon[data-icon="'+v+'"]').append('<img src="">')
	$('.icon[data-icon="'+v+'"] img').attr("src",svg.default);

});

